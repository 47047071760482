import $ from 'jquery'

function setSrollTop () {
  var scrollTop = $(window).scrollTop()
  if (scrollTop == 0) {
    $('.header').removeClass('header-light')
  }

  if (scrollTop > 20 && !$('.header').hasClass('header-light')) {
    $('.header').addClass('header-light')
  }
}

$(window).scroll((e) => {
  setSrollTop()
})

setSrollTop()
